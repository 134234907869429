//document

.fit-icon-2 {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


