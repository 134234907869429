// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color !important;
  }
  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      @include hover-focus() {
        color: darken($color, $emphasized-link-hover-darken-percentage) !important;
      }
    }
  }
}

@mixin text-ellipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
