//icon box design


.icon.icon-box{
	height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
    margin-right: 10px;
    font-size: 22px;
    text-decoration: none;
}
