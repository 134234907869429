.countdown {
	margin: 60px 0 0;
	padding: 0;
	li {
		span {
			font-size: 60px;
			display: inline-block;
			width: 100%;
			color: $primary;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
.iq-comingsoon-form {
	position: relative;
	button {
		position: absolute;
		right: 0;
		top: 0;
		padding: 11px 30px;
	}
}

@media(max-width: 320px){
	.iq-comingsoon-form button {
		padding: 11px 12px;
	}

}