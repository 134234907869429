@mixin circle-progress-varient ($parent, $color) {
    #{$parent} {
        svg {
            .circle-progress-value {
                stroke: $color;
                stroke-linecap: round;
            }
            .circle-progress-circle {
                stroke: lighten($color, 20%) !important;
            }
            .circle-progress-text {
                fill: $color;
                font-size: 23px;
                font-family: $font-family-title;
            }
        }
    }
}