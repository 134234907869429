.profile-icon.iq-icon-box {
	height: 58px;
	width: 58px;
	line-height: 58px;
	text-align: center;
	margin-right: unset;
	font-size: 26px;
	text-decoration: none;
}
.profile-overly {
	position: absolute;
	left: auto;
	bottom: 0;
	right: 0;
	background: $white;
	padding: 10px 30px;
	text-align: center;
	border-radius: 10px 10px 0 0;
}
ul.iq-contact-rest {
	li {
		a {
			p {
				word-break: break-all;
			}
		}
	}
}
.crm-p-image {
    position: absolute;
    top: auto;
    left: 69px;
    bottom: -9px;
    transition: all .3s cubic-bezier(.175,.885,.32,1.275);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    text-align: center;
	font-size: 12px;
	line-height: 25px;
    cursor: pointer;
    border: 5px solid $white;
}
@media(max-width:479px) {
    .profile-overly {
        h3{
            font-size: 14px;
        }
    }
}