.iq-progress-bar {
	background: $light;
	box-shadow: 0 0 0;
	height: 6px;
	margin: 0;
	position: relative;
	width: 100%;
	@if $enable-rounded == true {
		@include radius-mixin($border-radius);
	}
	>span {
		background: $primary none repeat scroll 0 0;
		position: relative;
		display: block;
		height: 100%;
		width: 0;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius);
		}
	}
	.progress-text {
		position: absolute;
		right: -10px;
		top: -25px;
		font-size: 13px;
		font-weight: 500;
	}
}
.iq-progress-bar.iq-progress-bar-icon {
	>span {
		&:after {
			content: "";
			width: 12px;
			height: 12px;
			@if $enable-rounded == true {
				@include radius-mixin($border-radius-circle);
			}
			background: $white;
			border: 3px solid transparent;
			position: absolute;
			bottom: -3px;
			right: 0;
			z-index: 1;
		}
	}
	>span.bg-primary {
		&:after {
			border-color: $primary;
		}
	}
	>span.bg-danger {
		&:after {
			border-color: $danger;
		}
	}
	>span.bg-success {
		&:after {
			border-color: $success;
		}
	}
	>span.bg-warning {
		&:after {
			border-color: $warning;
		}
	}
	>span.bg-info {
		&:after {
			border-color: $info;
		}
	}
	>span.bg-dark {
		&:after {
			border-color: $dark;
		}
	}
}

.progress-round.heatmap-progress {
	height: 97px;
	width: 97px;
	.progress-bar {
		border-width: 10px;
	}
	&::after {
		border: 10px solid #ff9273;
	}
}
.progress-round.income-progress {
	.progress-bar {
		border-width: 10px;
	}
	&::after {
		border: 10px solid #ff9273;
	}
}
.progress-round.alarams-progress {
	height: 110px;
	width: 110px;
}

.iq-progress-bar {
	.progress-text-one {
		position: absolute;
		right: -20px;
		top: -27px;
		font-size: 13px;
		font-weight: 500;
		background: $danger;
		color: $white;
		padding: 0px 5px;
	}
	>span.bg-primary {
		.progress-text-one {
			&:after {
				border-color: $primary transparent transparent transparent;
			}
		}
	}
	>span.bg-success {
		.progress-text-one {
			&:after {
				border-color: $success transparent transparent transparent;
			}
		}
	}
	>span.bg-warning {
		.progress-text-one {
			&:after {
				border-color: $warning transparent transparent transparent;
			}
		}
	}
		>span.bg-orange {
		.progress-text-one {
			&:after {
				border-color: $orange transparent transparent transparent;
			}
		}
	}
	>span.bg-danger {
		.progress-text-one {
			&:after {
				border-color: $danger transparent transparent transparent;
			}
		}
	}
	>span.bg-dark {
		.progress-text-one {
			&:after {
				border-color: $dark transparent transparent transparent;
			}
		}
	}
	>span.bg-info {
		.progress-text-one {
			&:after {
				border-color: $info transparent transparent transparent;
			}
		}
	}
	>span.bg-skyblue {
		.progress-text-one {
			&:after {
				border-color: $skyblue transparent transparent transparent;
			}
		}
	}
}
.progress-text-one {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
	}
}

.iq-circle-progressbar {
	.percent {
		font-size: 1.3rem !important;
	}
	svg {
		width: 100px !important;
		height: 100px !important;
	}
	margin: 0 auto;
	width: 100px !important;
	height: 100px !important;
}
.progress-round {
	width: 50px;
	height: 50px;
	background: none;
	position: relative;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		@if $enable-rounded == true {
			@include radius-mixin($border-radius-circle);
		}
		border: 3px solid $border-color;
		position: absolute;
		top: 0;
		left: 0;
	}
	>span {
		width: 50%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;
	}
	.progress-left {
		left: 0;
		.progress-bar {
			left: 100%;
			border-top-right-radius: 80px;
			border-bottom-right-radius: 80px;
			border-left: 0;
			-webkit-transform-origin: center left;
			transform-origin: center left;
		}
	}
	.progress-bar {
		width: 100%;
		height: 100%;
		background: none;
		border-width: 3px;
		border-style: solid;
		position: absolute;
		top: 0;
	}
	.progress-right {
		right: 0;
		.progress-bar {
			left: -100%;
			border-top-left-radius: 80px;
			border-bottom-left-radius: 80px;
			border-right: 0;
			-webkit-transform-origin: center right;
			transform-origin: center right;
		}
	}
	.progress-value {
		position: absolute;
		top: 0;
		left: 0;
	}
}
.progress-round.income-progress {
	height: 78px;
	width: 78px;
	.progress-bar {
		border-width: 10px;
	}
	&::after {
		border: 10px solid #ff9273;
	}
}
.progress-round.alarams-progress {
	height: 110px;
	width: 110px;
}
.progress-round.goal-progress {
	width: 130px;
	height: 130px;
}
.progress-round.goal-progress {
	.progress-bar {
		border-width: 10px;
	}
}
.progress-round.goal-progress {
	&::after {
		border: 10px solid #f4f4f4;
	}
}

// circle progressbar

@keyframes progress-loading-1 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(180deg));
	}
}
@keyframes progress-loading-2 {
	0% {
		@include transform-mixin( rotate(0deg));
	}
	100% {
		@include transform-mixin( rotate(80deg));
	}
}
.progress.progress-round {
	width: 60px;
	height: 60px;
	line-height: 60px;
	background: none;
	margin: 0 auto;
	box-shadow: none;
	position: relative;
	>span {
		width: 50%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		z-index: 1;
	}
	.progress-left {
		left: 0;
		.progress-bar {
			left: 100%;
			border-top-right-radius: 80px;
			border-bottom-right-radius: 80px;
			border-left: 0;
			-webkit-transform-origin: center left;
			transform-origin: center left;
			animation: progress-loading-2 1.5s linear forwards 1.8s;
		}
	}
	.progress-bar {
		border-width: 5px;
	}
	.progress-right {
		right: 0;
		.progress-bar {
			left: -100%;
			border-top-left-radius: 80px;
			border-bottom-left-radius: 80px;
			border-right: 0;
			-webkit-transform-origin: center right;
			transform-origin: center right;
			animation: progress-loading-1 1.8s linear forwards;
		}
	}
	&:after {
		content: "";
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 5px solid $light;
		position: absolute;
		top: 0;
		left: 0;
	}
	.progress-value {
		font-size: 16px;
		left: 18px;
	}
}
.progress.danger {
	.progress-bar {
		border-color: $danger;
	}
}
.progress.warning {
	.progress-bar {
		border-color: $warning;
	}
}
.progress.success {
	.progress-bar {
		border-color: $success;
	}
}
.progress.info {
	.progress-bar {
		border-color: $info;
	}
}
.progress.primary {
	.progress-bar {
		border-color: $primary;
	}
}
.progress.orange {
	.progress-bar {
		border-color: $orange;
	}
}

// Circle Progress

.circle-progress-value {
	stroke-width: 8px;
}
.circle-progress-circle {
	stroke-width: 8px;
}
.circle-progress {
	& > svg {
		height: 75px;
		width: 75px;
	}
}
@each $color, $value in $theme-colors {
	@include circle-progress-varient(".circle-progress-#{$color}", $value);
}
