.iq-timeline {
	margin: 0 0 0 20px;
	padding: 0;
	width: 100%;
	li {
		margin-left: 35px;
		position: relative;
		padding: 15px 15px 0 5px;
		list-style-type: none;
		.timeline-dots {
			position: absolute;
			top: 20px;
			left: -54px;
			border: 3px solid $primary;
			border-radius: 90px;
			padding: 5px;
			background: $card-bg;
			height: 40px;
			width: 40px;
			line-height: 25px;
			text-align: center;
		}
	}
}

.iq-timeline {
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		@include transform-mixin( translateX(-50%));
		width: 2px;
		height: 100%;
		background-color: #ccc;
	}
}
.iq-timeline {
	ul {
		li {
			width: 50%;
			padding: 5px 30px 40px 30px;
			cursor: pointer;
			margin: 0px;
			&:nth-child(odd) {
				float: left;
				text-align: right;
				clear: both;
				.timeline-dots {
					right: -8px;
					left: auto;
				}
				.time {
					padding-left: 10px;
					right: -145px;
					@include shadow-mixin( 0px 0px 0px 3px rgba(80, 181, 255, .3));
				}
			}
			&:nth-child(even) {
				float: right;
				text-align: left;
				clear: both;
				.timeline-dots {
					left: -8px;
					right: auto;
				}
				.time {
					text-align: right;
					padding-right: 10px;
					left: -145px;
					@include shadow-mixin( 0px 0px 0px 3px rgba(73, 240, 211, .3));
				}
			}
			.time {
				position: absolute;
				top: 15px;
				background-color: $primary;
				color: $white;
				text-align: left;
				width: 120px;
				height: 28px;
				line-height: 28px;
				border-radius: 20px;
			}
		}
	}
	li {
		.timeline-dots {
			height: 15px;
			width: 15px;
			background: $white !important;
		}
	}
}
.iq-timeline0 {
	ul {
		li {
			width: 50%;
			padding: 5px 30px 40px 30px;
			cursor: pointer;
			margin: 0px;
			&:nth-child(odd) {
				float: left;
				text-align: left;
				width: 100%;
				padding: 0 0 0 60px;
				margin-bottom: 20px;
				.timeline-dots {
					left: 12px;
					right: auto;
				}
				.timeline-dots.timeline-dot1 {
					left: 12px;
					right: auto;
				}
			}
			&:nth-child(even) {
				float: left;
				text-align: left;
				width: 100%;
				padding: 0 0 0 60px;
				margin-bottom: 20px;
				.timeline-dots {
					left: 12px;
					right: auto;
				}
				.timeline-dots.timeline-dot1 {
					left: 12px;
					right: auto;
				}
			}
		}
	}
	li {
		.timeline-dots {
			height: 15px;
			width: 15px;
			background: $white !important;
		}
		.timeline-dots1 {
			position: absolute;
			top: 0;
			left: 0;
			border: 3px solid $primary;
			border-radius: 90px;
			padding: 5px;
			background: $white;
			height: 40px;
			width: 40px;
			line-height: 25px;
			text-align: center;
		}
		.timeline-dots.timeline-dot1 {
			height: 15px;
			width: 15px;
		}
	}
}
.iq-timeline1 {
	ul {
		li {
			&:nth-child(odd) {
				float: left;
				text-align: right;
				clear: both;
				margin-left: -19px;
				.timeline-dots {
					right: -28px;
					left: auto;
				}
			}
			&:nth-child(even) {
				float: right;
				text-align: left;
				clear: both;
				margin-right: -20px;
				.timeline-dots {
					left: -27px;
					right: auto;
				}
			}
			width: 50%;
			padding: 20px 30px 0px 30px;
			cursor: pointer;
			margin: 0px;
		}
	}
}
.iq-timeline2 {
	ul {
		li {
			&:nth-child(odd) {
				float: left;
				text-align: left;
				width: 100%;
				padding: 0 0 0 60px;
				margin-bottom: 20px;
			}
			&:nth-child(even) {
				float: left;
				text-align: left;
				width: 100%;
				padding: 0 0 0 60px;
				margin-bottom: 20px;
			}
		}
	}
	&:before {
		content: '';
		position: absolute;
		left: 30px;
		transform: translateX(-50%);
		width: 2px;
		height: 100%;
		background-color: #ccc;
	}
	.content-date {
		position: absolute;
		top: 0;
		left: 0;
		width: 60px;
		height: 60px;
		line-height: 22px;
		padding-top: 8px;
		text-align: center;
		margin-left: 0px;
		border-radius: 100%;
		.date {
			display: block;
			font-size: 20px;
			font-weight: 700;
		}
	}
}
.iq-timeline0 {
	li {
		margin-left: 35px;
		position: relative;
		padding: 15px 15px 0 5px;
		list-style-type: none;
		.timeline-dots {
			position: absolute;
			top: 20px;
			left: -54px;
			border: 3px solid $primary;
			border-radius: 90px;
			padding: 5px;
			background: $card-bg;
			height: 40px;
			width: 40px;
			line-height: 25px;
			text-align: center;
		}
	}
	&:before {
		content: '';
		position: absolute;
		left: 20px;
		@include transform-mixin(translateX(-50%));
		width: 2px;
		height: 100%;
		background-color: #ccc;
	}
}
.iq-timeline1 {
	li {
		margin-left: 35px;
		position: relative;
		padding: 15px 15px 0 5px;
		list-style-type: none;
		.timeline-dots {
			position: absolute;
			top: 20px;
			left: -54px;
			border: 3px solid $primary;
			border-radius: 90px;
			padding: 5px;
			background: $white;
			height: 15px;
			width: 15px;
			line-height: 25px;
			text-align: center;
		}
	}
	&:before {
		content: '';
		position: absolute;
		left: 50%;
		@include transform-mixin( translateX(-50%));
		width: 2px;
		height: 100%;
		background-color: #ccc;
	}
}
.iq-timeline2 {
	li {
		margin-left: 0px;
		position: relative;
		padding: 15px 15px 0 5px;
		list-style-type: none;
	}
}

@media (max-width:575px) {
	.iq-timeline {
		ul {
			li {
				padding-left: 10px;
				padding-right: 10px;
				.time {
					width: 90px;
				}
				&:nth-child(odd) {
					.time {
						right: -115px;
					}
				}
				&:nth-child(even) {
					.time {
						left: -115px;
					}
				}
			}
		}
	}
}